import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import pdf from "../../Assets/CV_Adrien_BASSET.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import {useTranslation} from "react-i18next";

function Resume() {
    const { t } = useTranslation();
    return (
        <Container fluid className="resume-section">
            <Particle />
            <Container>
                <Row style={{ justifyContent: "center", position: "relative" }}>
                    <Button id="cvDownload" variant="primary" href={pdf} target="_blank">
                        <AiOutlineDownload />
                        &nbsp;{t('Download CV')}
                    </Button>
                </Row>
                <Row className="resume">
                    <Col md={6} className="resume-left">
                        <h3 className="resume-title">{t('Experience')}</h3>
                        <Resumecontent
                            title={t('Arcelor title')}
                            date={t('Arcelor date')}
                            content={[
                                t('Arcelor part1'),
                                t('Arcelor part2'),
                                ]}
                        />
                        <Resumecontent
                            title={t('ArcelorFreelance title')}
                            date={t('ArcelorFreelance date')}
                            content={[
                                t('ArcelorFreelance part1'),
                                ]}
                        />
                        <Resumecontent
                            title={t('Stellantis title')}
                            date={t('Stellantis date')}
                            content={[
                                t('Stellantis part1'),
                                t('Stellantis part2'),
                                t('Stellantis part3'),
                                ]}
                        />
                        <Resumecontent
                            title={t('MaisonVETO title')}
                            date={t('MaisonVETO date')}
                            content={[
                                t('MaisonVETO part1'),
                                t('MaisonVETO part2'),
                                t('MaisonVETO part3'),
                            ]}
                        />
                        <Resumecontent
                            title={t('Creapharm title')}
                            date={t('Creapharm date')}
                            content={[
                                t('Creapharm part1'),
                                t('Creapharm part2'),
                            ]}
                        />
                    </Col>
                    <Col md={6} className="resume-right">
                        <h3 className="resume-title">{t('Education')}</h3>
                        <Resumecontent
                            title={t('CESI title')}
                            date={t('CESI date')}
                            content={[
                                t('CESI part1'),
                            ]}
                        />
                        <Resumecontent
                            title={t('SJBS title')}
                            date={t('SJBS date')}
                            content={[
                                t('SJBS part1'),
                            ]}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center", position: "relative" }}>
                    <Button id="cvDownload" variant="primary" href={pdf} target="_blank">
                        <AiOutlineDownload />&nbsp;{t('Download CV')}
                    </Button>
                </Row>
            </Container>
        </Container>
    );
}

export default Resume;
