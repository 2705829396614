import React from "react";
import "./Techstack.css";
import {
    DiReact,
} from "react-icons/di";
import {
    SiJavascript,
    SiPhp,
    SiSymfony,
    SiBootstrap,
    SiCss3,
    SiNextdotjs,
    SiDirectus,
    SiDocker,
} from "react-icons/si";
import {useTranslation} from "react-i18next";

function Techstack() {
    const { t } = useTranslation();
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%"}} className="techCat">
                <h1 className="project-heading">
                    <strong className="purple">{t('Web Frontend')}</strong> {t('Skillset')}  <strong className="purple">{t('Web frontend fr')}</strong>
                </h1>
                <div className={"techWrapper"}>
                    <div className={"tech"}>
                        <SiJavascript style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Javascript</h2>
                            <div>{t('JSDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <DiReact style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>React</h2>
                            <div>{t('ReactDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiNextdotjs style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Next.js</h2>
                            <div>{t('NextJsDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiBootstrap style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Bootstrap</h2>
                            <div>{t('BootstrapDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiCss3 style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>CSS</h2>
                            <div>{t('CSSDesc')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "100%"}} className="techCat">
                <h1 className="project-heading">
                    <strong className="purple">{t('Web Backend')}</strong> {t('Skillset')}  <strong className="purple">{t('Web backend fr')}</strong>
                </h1>
                <div className={"techWrapper"}>
                    <div className={"tech"} >
                        <SiPhp style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>PHP</h2>
                            <div>{t('PHPDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiSymfony style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Symfony</h2>
                            <div>{t('SymfonyDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiDirectus style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Directus</h2>
                            <div>{t('DirectusDesc')}</div>
                        </div>
                    </div>
                    <div className={"tech"}>
                        <SiDocker style={{ fontSize : "4.7em" }} />
                        <div className={"techDesc"}>
                            <h2>Docker</h2>
                            <div>{t('DockerDesc')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Techstack;
