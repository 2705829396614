import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    return (
        <>
            <Container fluid className="home-section" id="home">
                <Particle />
                <Container className="home-content">
                    <Row>
                        <Col md={7} className="home-header">
                            <h1 style={{ paddingBottom: 15 }} className="heading">
                                {t('Hello')} <span className="wave">👋🏻</span>
                            </h1>

                            <h1 className="heading-name">
                                {t('Im')}
                                <strong className="main-name"> ADRIEN BASSET</strong>
                            </h1>

                            <div style={{ padding: 50, textAlign: "left" }}>
                                <Type />
                            </div>
                        </Col>

                        <Col md={5} style={{ paddingBottom: 20 }}>
                            <img src={homeLogo} alt="home pic" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Home2 />
        </>
    );
}

export default Home;
