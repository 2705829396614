import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            //Home
            "Im": "I'M",
            "Hello": "Hi There!",
            "Find": "FIND ME ON",
            "Feel": "Feel free to",
            "Connect": "connect",
            "With me": "with me",
            //Navbar
            "Home": "Home",
            "Skills": "Skills",
            "Projects": "Projects",
            "Resume": "Resume",
            "Lang": "Language",
            "French": "French",
            "English": "English",
            //Skills
            "Tools": "Tools",
            "I use": "I use",
            "Web Backend": "Web Backend",
            "Web Frontend": "Web Frontend",
            "Skillset": "Skillset",
            "Web backend fr": "",
            "Web frontend fr": "",
            "JSDesc" :  "I'm pretty comfortable with this language, I've used it on every project I've done so far. " +
                        "I've recently stepped up my practice of this language in my latest projects.",
            "ReactDesc" :   "I have done two projects with this Framework to learn how to use it. " +
                            "I find it handy for making small showcase sites or as a complement to an API for projects with a lot of client-side processing.",
            "BootstrapDesc" : "I use this Framework on many of my projects because it allows me to take advantage of already written CSS classes and thus save time when integrating different pages.",
            "CSSDesc" : "I use this Framework on many of my projects because it allows me to take advantage of already written CSS classes. " +
                        "It saves time during the integration of different pages.",
            "PHPDesc" : "The first web programming language I learned, all the corporate internships I've done so far have been related to this language.",
            "SymfonyDesc" : "The first Framework I learned to use, I did two internships in companies related to this Framework. " +
                "I have over a year of experience on this Framework.",
            "DirectusDesc": "It is a Headless CMS. I have learned that sometimes it is necessary to put aside one's pride and use no-code or low-code tools when they allow us to save time. Programming is not an end in itself but a means to achieve our goals.",
            "DockerDesc": "With its container system, it allows packaging applications with all their necessary components, thereby facilitating their deployment and execution in any environment.",
            "NextJsDesc": "It is a web development framework based on React that offers advanced features such as server-side rendering, dynamic routing, and static page generation. It's great for developing more comprehensive websites or when looking to improve SEO.",
            //Projets
            "My Recent": "My Recent",
            "Works": "Works",
            "Works description": "Here are a few projects I've worked on recently.",
            "TicTacToe":    "Simple TicTacToe build in React. " +
                            "I made this game to improve my skills with reactJS.",
            "letterCounter":    "Simple desktop application used to analyse .txt files. " +
                                "This application is using MVC structure and multi-threading to be more efficient. " +
                                "It's integrate a real time progress bar and saving serialized data in a JSON file.",
            "Portfolio":    "Simple portfolio in React. " +
                            "I made this website to discover reactJS and get a platform to show my resume & projects",
            "iaVignobles": "Design and implementation of a conference on the theme of artificial intelligence in service of vineyards presented in collaboration with a colleague.",
            "euphron": "Development of an algorithm aimed at facilitating weight adjustment during weightlifting sessions. It has been integrated into a Progressive Web App (PWA) to provide consistent support throughout the sessions. /!\\Project still under development./!\\",
            "luna": "I created a PWA to help my girlfriend find the optimal bedtime and wake-up time.",
            "distribution": "I designed and developed the website for ArcelorMittal Distribution Europe covering 12 European countries.",
            "ViewProject": "View Project",
            //Resume
            "Download CV": "Download CV",
            "Experience": "Experience",

            //Professional experiences
            "Arcelor title": "SOFTWARE ENGINEER - Work-study [ArcelorMittal Distribution]",
            "Arcelor date": "September 2022 - Now",
            "Arcelor part1": "At the end of my previous freelance mission, the company entrusted me with the task of carrying out the project.",
            "Arcelor part2": "For this project, I work on the functional aspect, the creation of models, the programming, the maintenance, the training of the personnel and the deployment on the company's servers.",

            "ArcelorFreelance title": "IT CONSULTANT - Freelance [ArcelorMittal Distribution]",
            "ArcelorFreelance date": "June 2022 - July 2022",
            "ArcelorFreelance part1": "This mission involved the functional analysis and design of a web application project for ArcelorMittal Distribution Solution in several European countries as a freelance.",

            "Stellantis title": "JUNIOR WEB DEVELOPER [Stellantis]",
            "Stellantis date": "September 2021 - January 2022",
            "Stellantis part1": "I worked as a developer for the Stellantis factory in Slovakia in the digitalization and innovation department called \"Inolab\".",
            "Stellantis part2": "During this internship, I worked in collaboration with other developers on several web application projects.",
            "Stellantis part3": "These projects aimed at digitizing internal processes in order to improve the comfort and efficiency of teams from different departments within the plant.",

            "MaisonVETO title": "JUNIOR WEB DEVELOPER | UI/UX Designer [MaisonVETO]",
            "MaisonVETO date": "January 2021 - April 2021",
            "MaisonVETO part1": "Design & develop a website used by debtors to pay back their creditors. This project has been developed with Symfony (PHP Framework).",
            "MaisonVETO part2": "This platform has been made for veterinarians to fight against bad payer.",
            "MaisonVETO part3": "I also develop a part of the platform used by creditors to manage & track the status of each debt.",

            "Creapharm title": "JUNIOR WEB DEVELOPER | UI/UX Designer [Creapharm]",
            "Creapharm date": "March 2020 - July 2020",
            "Creapharm part1": "Design & develop a internal website for employees. This project was developed with only vanilla PHP.",
            "Creapharm part2": "This platform hosts tools such as : a directory, a blog, an events calendar or an idea box.",
            //Education
            "Education": "Education",

            "CESI title": "COMPUTER SCIENCE ENGINEER [CESI Engineering School, Reims - France]",
            "CESI date": "2018 - Now",
            "CESI part1": "Currently studying",

            "SJBS title": "GENERAL & TECHNOLOGICAL BACCALAUREATE ['SJBS' High school, Reims - France]",
            "SJBS date": "2015 - 2018",
            "SJBS part1": "Mention: Good",

        }
    },
    fr: {
        translation: {
            //Home
            "Im": "JE SUIS",
            "Hello": "Salut!",
            "Find": "TROUVEZ MOI ICI",
            "Feel": "N'hésitez pas à me",
            "Connect": "contacter",
            "With me": "",
            //Navbar
            "Home": "Accueil",
            "Skills": "Compétences",
            "Projects": "Projets",
            "Resume": "Parcours",
            "Lang": "Langue",
            "French": "Français",
            "English": "Anglais",
            //Compétences
            "Tools": "Outils",
            "I use": "que j'utilise",
            "Web Backend": "",
            "Web Frontend": "",
            "Skillset": "Compétences",
            "Web backend fr": "Web Backend",
            "Web frontend fr": "Web Frontend",
            "JSDesc" :  "Je suis plutôt à l'aise avec ce langage, je l'ai utilisé sur chacun des projets que j'ai réalisés jusqu'ici. " +
                        "J'ai récemment renforcé ma pratique de ce langage dans mes derniers projets.",
            "ReactDesc" :   "J'ai réalisé deux projets avec ce Framework afin d'apprendre à m'en servir. " +
                            "Je le trouve pratique pour réaliser de petits sites vitrines ou en complément d'une API pour des projets avec beaucoup de traitement coté client.",
            "BootstrapDesc" : "J'utilise ce Framework sur beaucoup de mes projets car il me permet de profiter de classes CSS déjà écrites et ainsi gagner du temps lors de l'intégration des différentes pages.",
            "CSSDesc" : "Je suis à l'aise avec la majorité des règles CSS, je suis aussi capable d'utiliser SASS lorsque c'est nécessaire.",
            "PHPDesc" : "Le premier langage de programmation  web que j'ai appris, tous les stages en entreprise que j'ai réalisés jusqu'ici étaient en rapport avec ce langage.",
            "SymfonyDesc" : "Le premier Framework que j'ai appris à utiliser, j'ai réalisé deux stages en entreprise en rapport avec ce Framework. " +
                            "J'ai plus d'un an d'expérience sur ce Framework.",
            "DirectusDesc" : "C'est un CMS Headless. J'ai appris qu'il était parfois nécessaire de mettre sa fierté de côté pour utiliser des outils no-code ou low-code quand ils permettent de gagner du temps. La programmation n'est pas une fin en soit mais un moyen d'atteindre ses objectifs.",
            "DockerDesc" : "Avec son système de container, il permet d'emballer des applications avec tous leurs composants nécessaires, facilitant ainsi leur déploiement et leur exécution sur n'importe quel environnement.",
            "NextJsDesc" : "C'est un framework de développement web basé sur React qui offre des fonctionnalités avancées telles que le rendu côté serveur, le routage dynamique et la génération de pages statiques. Un vrai pour développer des sites web plus complets ou lorsque l'on cherche à améliorer le SEO.",
            //Projects
            "My Recent": "Mes Derniers",
            "Works": "Travaux",
            "Works description": "Voici quelques projets sur lesquels j'ai travaillé récemment.",
            "TicTacToe":    "TicTacToe simple réalisé avec React. " +
                            "J'ai fais ce jeu dans le but d'améliorer mes compétences avec ReactJS.",
            "letterCounter":    "Application de bureau simple servant à analyser des fichiers .txt. " +
                                "Cette application utilise une structure MVC et du multi-threading pour améliorer son efficacité. " +
                                "Elle intègre une barre de progression en temps réelle. Elle est aussi capable de serializer et sauvegarder les données analysées au format JSON.",
            "Portfolio":    "Portfolio simple en React. " +
                            "J'ai créé ce site site web pour découvrir reactJS et obtenir une plateforme permettant de présenter mon parcours et mes projets.",
            "iaVignobles": "Conception et réalisation d'une conférence sur le thème de l'intelligence artificielle au service des vignobles présentée en collaboration avec un camarade.",
            "euphron": "Elaboration d'un algorithme dans le but de faciliter l'ajustement des charges lors de séances d'entraînement avec haltères. Ce dernier a été intégré à une Progressive Web App (PWA) pour fournir un soutien constant tout au long des séances. /!\\Projet encore en cours de développement./!\\",
            "luna": "J'ai réalisé une PWA, pour aider ma copine à trouver l'heure optimale pour aller se coucher et se réveiller.",
            "distribution": "J'ai conçu et réalisé le site web de ArcelorMittal Distribution Europe pour 12 pays d'Europe.",
            "ViewProject": "Voir le projet",
            //Pacours
            "Download CV": "Télécharger le CV",
            "Experience": "Expérience",
            //Expériences pro
            "Arcelor title": "INGENIEUR LOGICIEL - Alternance [ArcelorMittal Distribution]",
            "Arcelor date": "Septembre 2022 - Maintenant",
            "Arcelor part1": "A l'issue de ma précedente mission en Freelance, l'entreprise m'a confiée la charge de la réalisation de cette dernière.",
            "Arcelor part2": "Pour ce projet, je travaille sur l'aspect fonctionnel, la création de maquettes, la programmation, la maintenance, la formation du personnel ainsi que le déploiement sur les serveurs de l'entreprise.",

            "ArcelorFreelance title": "CONSULTANT IT - Freelance [ArcelorMittal Distribution]",
            "ArcelorFreelance date": "Juin 2022 - Juillet 2022",
            "ArcelorFreelance part1": "Cette mission portait sur l'analyse fonctionnelle et la conception d'un projet d\’application web pour ArcelorMittal Distribution Solution dans plusieurs pays d\’Europe en tant que freelance.",

            "Stellantis title": "DEVELOPPEUR WEB JUNIOR [Stellantis]",
            "Stellantis date": "Septembre 2021 - Janvier 2022",
            "Stellantis part1": "J'ai travaillé en tant que développeur pour l'usine Stellantis en Slovaquie dans le service de digitalisation et d'innovation \"Inolab\".",
            "Stellantis part2": "Durant ce stage, j'ai travaillé en collaboration avec d'autres développeur sur plusieurs projets d'applications web.",
            "Stellantis part3": "Ces projets visaient à digitaliser des process internes afin d'améliorer le confort et l'efficacité des équipes de différents services au sein de l'usine.",

            "MaisonVETO title": "DEVELOPPEUR WEB JUNIOR | Designer UI/UX [MaisonVETO]",
            "MaisonVETO date": "Janvier 2021 - Avril 2021",
            "MaisonVETO part1": "J'ai réalisé le design et j'ai développé un site web utilisé par les débiteurs pour rembourser leurs créanciers. Ce projet a été développé avec Symfony (Framework PHP).",
            "MaisonVETO part2": "Cette plateforme a été conçue pour que les vétérinaires puissent lutter contre les mauvais payeurs.",
            "MaisonVETO part3": "J'ai également développé en collaboration avec d'autres stagiaires une partie de la plateforme utilisée par les créanciers pour gérer et suivre le statut de chaque dette.",

            "Creapharm title": "DEVELOPPEUR WEB JUNIOR | Designer UI/UX [Creapharm]",
            "Creapharm date": "Mars 2020 - Juillet 2020",
            "Creapharm part1": "Conception et développement d'un site web interne pour les employés. Ce projet a été développé avec seulement vanilla PHP.",
            "Creapharm part2": "Cette plateforme accueille des outils tels que : un annuaire, un blog, un calendrier d'événements ou une boîte à idées.",
            //Formation
            "Education": "Formation",

            "CESI title": "ECOLE D'INGENIEUR INFORMATIQUE [CESI Ecole d'ingénieurs, Reims - France]",
            "CESI date": "2018 - Aujourd'hui",
            "CESI part1": "En cours",

            "SJBS title": "BACALAUREAT GENERAL ET TECHNOLOGIQUE ['SJBS' lycée, Reims - France]",
            "SJBS date": "2015 - 2018",
            "SJBS part1": "Mention: Bien",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;