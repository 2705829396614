import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import {useTranslation} from "react-i18next";

function Skills() {
    const { t } = useTranslation();
    return (
        <Container fluid className="about-section">
            <Particle />
            <Container>
                <Techstack />
                <Toolstack />
            </Container>
        </Container>
    );
}

export default Skills;
