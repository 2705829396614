import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import adrien from "../../Assets/Projects/adrien.png";
import letter from "../../Assets/Projects/LetterCounter.png";
import tictac from "../../Assets/Projects/TicTacToe.png";
import euphron from "../../Assets/Projects/euphronProjet.png";
import luna from "../../Assets/Projects/luna.png";
import iaVignobles from "../../Assets/Projects/iaVignobles.png";
import distribution from "../../Assets/Projects/distribution.png";

import {useTranslation} from "react-i18next";

function Projects() {
  const { t } = useTranslation();
  return (
      <Container fluid className="project-section">
        <Particle />
        <Container>
          <h1 className="project-heading">
            {t('My Recent')} <strong className="purple">{t('Works')} </strong>
          </h1>
          <p style={{ color: "white" }}>
            {t('Works description')}
          </p>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={distribution}
                  isBlog={false}
                  title="Site web ArcelorMittal"
                  description={t('distribution')}
                  link="https://distribution.arcelormittal.com/"
              />
            </Col>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={iaVignobles}
                  isBlog={false}
                  title="Conférence"
                  description={t('iaVignobles')}
                  link="https://iavignobles.vercel.app/"
              />
            </Col>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={euphron}
                  isBlog={false}
                  title="Euphron"
                  description={t('euphron')}
                  link="https://euphron.vercel.app/"
              />
            </Col>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={luna}
                  isBlog={false}
                  title="Luna"
                  description={t('luna')}
                  link="https://dormir.vercel.app/"
              />
            </Col>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={tictac}
                  isBlog={false}
                  title="TicTacToe"
                  description={t('TicTacToe')}
                  link="https://tictactoe-adrienbst.vercel.app/"
              />
            </Col>
            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={letter}
                  isBlog={false}
                  title="letterCounter"
                  description={t('letterCounter')}
                  link="https://bitbucket.org/adrienbst/lettercounter/src/master/"
              />
            </Col>

            <Col md={6} lg={4} className="project-card">
              <ProjectCard
                  imgPath={adrien}
                  isBlog={false}
                  title="Portfolio"
                  description={t('Portfolio')}
                  link="https://www.adrienbasset.fr/"
              />
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Projects;
