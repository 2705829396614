import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../Assets/logo.png";
import eng from "../Assets/eng.png";
import fra from "../Assets/fra.png";
import { Link } from "react-router-dom";
import {
  AiOutlineCode,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";
import { MdOutlineTranslate } from "react-icons/md";
import {useTranslation} from "react-i18next";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(true);

  function scrollHandler() {
    if (window.scrollY >= 0) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);
  const { t, i18n } = useTranslation();

  return (
      <Navbar
          expanded={expand}
          fixed="top"
          expand="md"
          className={navColour ? "sticky" : "navbar"}
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="img-fluid logo" alt="brand" />
          </Navbar.Brand>
          <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => {
                updateExpanded(expand ? false : "expanded");
              }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" defaultActiveKey="#home">
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                  <AiOutlineHome style={{ marginBottom: "2px" }} /> {t('Home')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                    as={Link}
                    to="/skills"
                    onClick={() => updateExpanded(false)}
                >
                  <AiOutlineCode style={{ marginBottom: "2px" }} /> {t('Skills')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                    as={Link}
                    to="/project"
                    onClick={() => updateExpanded(false)}
                >
                  <AiOutlineFundProjectionScreen
                      style={{ marginBottom: "2px" }}
                  />{" "}
                  {t('Projects')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                    as={Link}
                    to="/resume"
                    onClick={() => updateExpanded(false)}
                >
                  <CgFileDocument style={{ marginBottom: "2px" }} /> {t('Resume')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id={'dropdownContainer'}>
                <Dropdown>
                  <Dropdown.Toggle>
                    <MdOutlineTranslate
                        style={{ marginBottom: "2px" }}
                    />{t('Lang')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {i18n.changeLanguage("fr");}}><img src={fra} class="flag" alt="french-flag" />{t('French')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => {i18n.changeLanguage("en");}}><img src={eng} class="flag" alt="english-flag" />{t('English')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default NavBar;
