import React from "react";
import "./Toolstack.css";
import {Col, Row} from "react-bootstrap";
import {
    SiTrello,
    SiIntellijidea,
    SiAdobexd,
    SiApache, SiFigma,
} from "react-icons/si";
import {
    DiGit,
} from "react-icons/di";
import {useTranslation} from "react-i18next";

function Toolstack() {
    const { t } = useTranslation();
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <h1 className="project-heading" style={{margin: "20px"}}>
                <strong className="purple">{t('Tools')}</strong> {t('I use')}
            </h1>
            <Col xs={4} md={2} className="tech-icons">
                <SiTrello />
                <div className="toolName">Trello</div>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <SiIntellijidea />
                <div className="toolName">PhpStorm</div>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiGit />
                <div className="toolName">Git</div>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <SiAdobexd />
                <div className="toolName">AdobeXD</div>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <SiFigma />
                <div className="toolName">Figma</div>
            </Col>
        </Row>
    );
}

export default Toolstack;
